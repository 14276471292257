import { default as aboutlAUAmFPn1JMeta } from "/opt/app/pages/about.vue?macro=true";
import { default as _91page_93mgaPke34jTMeta } from "/opt/app/pages/blog/[page].vue?macro=true";
import { default as indexEohrYP9OICMeta } from "/opt/app/pages/blog/index.vue?macro=true";
import { default as capitalsC8CPT5hCFMeta } from "/opt/app/pages/capital.vue?macro=true";
import { default as contactsPaPMo63Dd8Meta } from "/opt/app/pages/contacts.vue?macro=true";
import { default as documentsaoxiioVcZEMeta } from "/opt/app/pages/dashboard/documents.vue?macro=true";
import { default as index6ZqpsBs84HMeta } from "/opt/app/pages/dashboard/index.vue?macro=true";
import { default as portfolioVvvlYOxXKkMeta } from "/opt/app/pages/dashboard/portfolio.vue?macro=true";
import { default as profileaqFkneUoOvMeta } from "/opt/app/pages/dashboard/profile.vue?macro=true";
import { default as referralsxHABqqHLlJMeta } from "/opt/app/pages/dashboard/referrals.vue?macro=true";
import { default as documentsA3LwOxxcLVMeta } from "/opt/app/pages/documents.vue?macro=true";
import { default as founderuOyCfn6bu3Meta } from "/opt/app/pages/founder.vue?macro=true";
import { default as _91page_93nWZkQTaZJcMeta } from "/opt/app/pages/funds/[page].vue?macro=true";
import { default as index7zHUxHdrhvMeta } from "/opt/app/pages/funds/index.vue?macro=true";
import { default as handbookwS0CC6gK1LMeta } from "/opt/app/pages/handbook.vue?macro=true";
import { default as index1JQZ1qn7X7Meta } from "/opt/app/pages/index.vue?macro=true";
import { default as inzhur_45development9cMhFkcsqlMeta } from "/opt/app/pages/inzhur-development.vue?macro=true";
import { default as _91page_93G0REO17BHXMeta } from "/opt/app/pages/property/[page].vue?macro=true";
import { default as indexc3X2wruJFIMeta } from "/opt/app/pages/property/index.vue?macro=true";
import { default as referral_45program3zT5Xk5NJPMeta } from "/opt/app/pages/referral-program.vue?macro=true";
import { default as repoutDViFbfm0Meta } from "/opt/app/pages/repo.vue?macro=true";
import { default as reset_45passwordPbYjOO8NxGMeta } from "/opt/app/pages/reset-password.vue?macro=true";
import { default as indexKv1TOdiA6kMeta } from "/opt/app/pages/signin/index.vue?macro=true";
import { default as documentsL6gVwsjFezMeta } from "/opt/app/pages/signup/documents.vue?macro=true";
import { default as pendingGJKP5KHuOaMeta } from "/opt/app/pages/signup/pending.vue?macro=true";
import { default as indexE4tc8OKLyuMeta } from "/opt/app/pages/signup/questionnaire/index.vue?macro=true";
import { default as signingOEYeC0rwxdMeta } from "/opt/app/pages/signup/questionnaire/signing.vue?macro=true";
import { default as component_45stubsUQ0AiknVkMeta } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsUQ0AiknVk } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___uk",
    path: "/about",
    component: () => import("/opt/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "blog-page___uk",
    path: "/blog/:page()",
    component: () => import("/opt/app/pages/blog/[page].vue").then(m => m.default || m)
  },
  {
    name: "blog___uk",
    path: "/blog",
    component: () => import("/opt/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "capital___uk",
    path: "/capital",
    component: () => import("/opt/app/pages/capital.vue").then(m => m.default || m)
  },
  {
    name: "contacts___uk",
    path: "/contacts",
    component: () => import("/opt/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-documents___uk",
    path: "/dashboard/documents",
    component: () => import("/opt/app/pages/dashboard/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___uk",
    path: "/dashboard",
    component: () => import("/opt/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-portfolio___uk",
    path: "/dashboard/portfolio",
    component: () => import("/opt/app/pages/dashboard/portfolio.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___uk",
    path: "/dashboard/profile",
    component: () => import("/opt/app/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-referrals___uk",
    path: "/dashboard/referrals",
    component: () => import("/opt/app/pages/dashboard/referrals.vue").then(m => m.default || m)
  },
  {
    name: "documents___uk",
    path: "/documents",
    component: () => import("/opt/app/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "founder___uk",
    path: "/founder",
    component: () => import("/opt/app/pages/founder.vue").then(m => m.default || m)
  },
  {
    name: "funds-page___uk",
    path: "/funds/:page()",
    component: () => import("/opt/app/pages/funds/[page].vue").then(m => m.default || m)
  },
  {
    name: "funds___uk",
    path: "/funds",
    component: () => import("/opt/app/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: "handbook___uk",
    path: "/handbook",
    component: () => import("/opt/app/pages/handbook.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/",
    component: () => import("/opt/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inzhur-development___uk",
    path: "/inzhur-development",
    component: () => import("/opt/app/pages/inzhur-development.vue").then(m => m.default || m)
  },
  {
    name: "property-page___uk",
    path: "/property/:page()",
    component: () => import("/opt/app/pages/property/[page].vue").then(m => m.default || m)
  },
  {
    name: "property___uk",
    path: "/property",
    component: () => import("/opt/app/pages/property/index.vue").then(m => m.default || m)
  },
  {
    name: "referral-program___uk",
    path: "/referral-program",
    component: () => import("/opt/app/pages/referral-program.vue").then(m => m.default || m)
  },
  {
    name: "repo___uk",
    path: "/repo",
    component: () => import("/opt/app/pages/repo.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___uk",
    path: "/reset-password",
    component: () => import("/opt/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "signin___uk",
    path: "/signin",
    component: () => import("/opt/app/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-documents___uk",
    path: "/signup/documents",
    component: () => import("/opt/app/pages/signup/documents.vue").then(m => m.default || m)
  },
  {
    name: "signup-pending___uk",
    path: "/signup/pending",
    component: () => import("/opt/app/pages/signup/pending.vue").then(m => m.default || m)
  },
  {
    name: "signup-questionnaire___uk",
    path: "/signup/questionnaire",
    component: () => import("/opt/app/pages/signup/questionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-questionnaire-signing___uk",
    path: "/signup/questionnaire/signing",
    component: () => import("/opt/app/pages/signup/questionnaire/signing.vue").then(m => m.default || m)
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/ocean",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/supermarket",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/inzhurbud",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/fund-1001",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/fund-2001",
    component: component_45stubsUQ0AiknVk
  },
  {
    name: component_45stubsUQ0AiknVkMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubsUQ0AiknVk
  }
]