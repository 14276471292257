import revive_payload_client_Frd8gASYWa from "/opt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/opt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l19uMh4P89 from "/opt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_T5aD6w6QZh from "/opt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/opt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/opt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/opt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/opt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/app/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/app/.nuxt/floating-vue.mjs";
import strapi_KSPFj1nzXf from "/opt/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import titles_GTyiibPwXi from "/opt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_WCZfpyw4WZ from "/opt/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_ZWq9TlLEe2 from "/opt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_t3yL13D7A3 from "/opt/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import i18n_huatSqtcYP from "/opt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import global_dY5SAi3BsR from "/opt/app/plugins/global.js";
import sentry_client_GoGQuZo4Et from "/opt/app/plugins/sentry.client.js";
import analytics_ABBiEqcBsv from "/opt/app/plugins/analytics.js";
import error_handler_VFH3VvK7yG from "/opt/app/plugins/error-handler.js";
import i18n_sVHQBgnb3t from "/opt/app/plugins/i18n.js";
import vue_awesome_paginate_l1b0wTlGPB from "/opt/app/plugins/vue-awesome-paginate.js";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_l19uMh4P89,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  floating_vue_EIcJ7xiw0h,
  strapi_KSPFj1nzXf,
  titles_GTyiibPwXi,
  defaults_WCZfpyw4WZ,
  siteConfig_ZWq9TlLEe2,
  inferSeoMetaPlugin_t3yL13D7A3,
  i18n_huatSqtcYP,
  global_dY5SAi3BsR,
  sentry_client_GoGQuZo4Et,
  analytics_ABBiEqcBsv,
  error_handler_VFH3VvK7yG,
  i18n_sVHQBgnb3t,
  vue_awesome_paginate_l1b0wTlGPB
]